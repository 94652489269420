import React, { Component, Fragment } from 'react';
import AdvancedCard from 'shared/components/advanced-card';
import PageHeader from 'shared/components/page-header';
import { Bar }from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { 
  Media, UncontrolledButtonDropdown,  Button, ButtonGroup, DropdownItem, DropdownToggle, DropdownMenu, Row, Col,
  Card, CardBody, CardTitle, CardHeader, Table, Badge
} from 'reactstrap';
import { 
  FiDownload, FiPrinter, FiCopy, FiPlus, FiRefreshCcw, FiPackage, FiEye, FiArrowUpRight
 } from 'react-icons/fi';
 import {
  FaFacebookF as IconFacebok,
  FaTwitter as IconTwitter,
  FaInstagram as IconInstagram,
  FaEnvelope as IconEmail,
  FaYoutube as IconYoutube
 } from 'react-icons/fa'
import {  
  Area, Line, ComposedChart,
  PieChart, Pie,
  XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell
} from 'recharts';
import IconCircle from 'shared/components/ui/IconCircle';

const data = [
  { hour: "1:00", valuePrev: 4347, value: 5347, }, { hour: "2:00", valuePrev: 7847, value: 6347 }, { hour: "3:00", valuePrev: 9347, value: 12347 }, 
  { hour: "4:00", valuePrev: 9772, value: 10772 }, { hour: "5:00", valuePrev: 12347, value: 14347 }, { hour: "6:00", valuePrev: 12000, value: 15000 }, 
];

const dataPie = [
  { name: 'Group A', value: 2400 }, { name: 'Group B', value: 4567 },
  { name: 'Group C', value: 8598 }, { name: 'Group D', value: 7800 }
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const dataCampaign = [
  { channel: 'Facebook', budget: '$600', remaining: '$150', roi: '20%', result: '+5423 Likes, +1746 Followers' },
  { channel: 'Twitter', budget: '$400', remaining: '$250', roi: '35%', result: '+12K Likes, +3865 Followers' },
  { channel: 'Instagram', budget: '$1325', remaining: '$164', roi: '15%', result: '+64K Likes, +15K Followers ' },
  { channel: 'YouTube', budget: '$832', remaining: '$614', roi: '25%', result: '+8746 Likes, +3865 Subscribers' },
  { channel: 'Email', budget: '$700', remaining: '$89', roi: '45%', result: '+2764 Clicks, +1865 Subscribers' }
];

const labelPrev = 'Previous Week';
const bgColorPrev = '#FFBB28'; 
const labelCurr = 'Current Week';
const bgColorCurr = '#524B83';

const dataJan = [{
  label: labelPrev,
  backgroundColor: bgColorPrev,
  data: [395, 457, 721, 522, 400, 420, 600]
},
{
  label: labelCurr,
  backgroundColor: bgColorCurr,
  data: [375, 557, 621, 600, 430, 440, 534]
}]

const dataFeb = [{
  label: labelPrev,
  backgroundColor: bgColorPrev,
  data: [295, 357, 700, 322, 220, 320, 430]
},
{
  label: labelCurr,
  backgroundColor: bgColorCurr,
  data: [275, 457, 521, 500, 230, 340, 434]
}]

const dataMar = [{
  label: labelPrev,
  backgroundColor: bgColorPrev,
  data: [495, 557, 440, 582, 430, 450, 500]
},
{
  label: labelCurr,
  backgroundColor: bgColorCurr,
  data: [475, 357, 692, 580, 420, 460, 522]
}]

const dataChartjs = [dataJan, dataFeb,dataMar];

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activeMonth: 0,
      randomKey: Math.random()*100,
      dataBarChart: {
        labels: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
        datasets: dataChartjs[0]
      }
    };
  }

  setLoading = () => {
    setTimeout(() => {
      this.setState({
        loading: false
      });
    }, 2000);

    this.setState({
      loading: true
    });
  }

  monthChanged = (month) => {
    let dataBarChart = this.state.dataBarChart;
    dataBarChart.datasets = dataChartjs[month];

    this.setState({
      activeMonth: month,
      randomKey: Math.random()*100,
      dataBarChart: dataBarChart
    });
  }

  render() {
    const {
      loading,
      activeMonth,
      dataBarChart
    } = this.state;

    return (
      <Fragment>        
        <Card className="card-transparent">
          <PageHeader
            title="Sales Income"
            description="Weekly sales comparison"
          >
            <ButtonGroup>
              <Button color="primary" outline active={activeMonth === 0} onClick={() => this.monthChanged(0)}>Jan</Button>
              <Button color="primary" outline active={activeMonth === 1} onClick={() => this.monthChanged(1)}>Feb</Button>
              <Button color="primary" outline active={activeMonth === 2} onClick={() => this.monthChanged(2)}>Mar</Button>
            </ButtonGroup>
          </PageHeader>          
          
          <div style={{ height: '300px' }}>
            <Bar
              key={this.state.randomKey}
              data={dataBarChart} 
              options={{
                maintainAspectRatio: false,
                tooltips: { enabled: false },
                legend: { 
                  position: 'bottom',
                  labels: { usePointStyle: true }
                },
                layout: {
                  padding: { top: 30}
                },
                scales: {
                  xAxes: [{
                    gridLines: { color: 'transparent' },
                    ticks: { fontSize: 14, fontColor: '#4C4F55' }
                  }],
                  yAxes: [{
                    display: false,
                    gridLines: { display: false },
                    
                  }]
                },              
                plugins: {
                  datalabels: {
                    color: function(context) {
                      let color;
                      if(context.dataset.label === 'Previous Week') {
                        color = '#FFBB28';
                      } else {
                        color = '#524B83';
                      }
            
                      return color;
                    },
                    align: 'top',
                    anchor: 'end',
                    font: {
                      size: '16',
                      weight: 'bold'
                    },
                    formatter: (value, context) => {
                      return '$' + value;
                    }
                  }
                }
              }}
            />
          </div>
        </Card>
        <Row>
          <Col lg="9">
            <AdvancedCard 
              title="Last Sent Campaign" 
              refreshable
              loading={loading}
              onClick={this.setLoading}
              >
              
              <Row>
                <Col md={5}>
                  <Media>
                    <Media left src="https://i.imgur.com/WIh0zbX.png" alt="" className="mr-3 img-thumbnail" />
                    <Media body>
                      <Media heading className="h6">Year End Sale Campaign</Media>
                      <span className="d-block text-muted">Regular campaign</span>
                      <span className="d-block text-muted">June 25, 2019</span>    
                      <UncontrolledButtonDropdown className="mt-3">
                        <DropdownToggle caret color="primary">View Report</DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem><FiDownload /> Download</DropdownItem>
                          <DropdownItem><FiPrinter /> Print</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      </Media>
                  </Media>
                </Col>
                <Col md={7}>
                  <div className="d-flex justify-content-between mt-2 mt-lg-0">
                    <div>
                      <span className="d-block color-accent big">52,732</span> <span className="text-muted">Emails Sent</span>
                    </div>
                    <div>
                      <span className="d-block color-accent big">15.82%</span> <span className="text-muted">Opened</span>
                    </div>
                    <div>
                      <span className="d-block color-accent big">5.35%</span> <span className="text-muted">Clicked</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div>
                      <span className="d-block color-accent big text-danger">0.18%</span> <span className="text-muted">Unsubscribed</span>
                    </div>
                    <div>
                      <span className="d-block color-accent big text-danger">0.02%</span> <span className="text-muted">Spam Complaints</span>
                    </div>
                    <div>
                      <span className="d-block color-accent big">7.23%</span> <span className="text-muted">CTR</span>
                    </div>
                  </div>
                  <div className="text-center text-md-right mt-3">
                    <Button color="secondary" outline><FiCopy /> Copy</Button>
                    <Button color="secondary ml-2" outline><FiPlus /> Create Template</Button>
                  </div>
                </Col>
              </Row>              
            </AdvancedCard>
          </Col>
          <Col lg="3">
            <Card>
              <CardBody className="text-center p-4">
                <span className="d-block text-big-gradient mt-3">1,468</span>
                <span className="d-block lead">New Subscribers</span>
                <span className="badge-metric my-2"><i className="icon ion-ios-arrow-up"></i> 15,23%</span>
                <span className="d-block small text-muted mb-4">vs. Last 7 Days</span>
                <Button color="primary" outline>View Stat</Button>
              </CardBody> 
            </Card>
          </Col>
        </Row>

        <Card>
          <Row>
            <Col lg={9}>
              <CardHeader className="bg-transparent border-0 d-flex justify-content-between">
                <CardTitle>Site Visits</CardTitle>
                <div>
                  <span className="text-success">+15,23%</span> <span className="text-muted">vs. Yesterday</span>
                </div>
              </CardHeader>
              <CardBody>
                <div style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <ComposedChart
                      data={data}
                    >
                      <CartesianGrid vertical={false} />
                      <XAxis dataKey="hour" />
                      <YAxis />
                      <Tooltip />
                      <defs>
                        <linearGradient id="areaGradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                          <stop offset="0" stopColor="#FFBB28"/>
                          <stop offset="1" stopColor="#FFF" stopOpacity="0"/>
                        </linearGradient>
                      </defs>
                      <Area type="monotone" dataKey="value" stroke="#FFBB28" strokeWidth={2} fill="url(#areaGradient)" />
                      <Line type="monotone" dataKey="valuePrev" stroke="#ff7f42" strokeWidth={2} />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </CardBody>
            </Col>
            <Col lg={3}>
              <CardBody>
                <CardTitle className="text-center">Sales</CardTitle>
                <div style={{ width: '100%', height: 200 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie dataKey="value" startAngle={200} endAngle={-20} data={dataPie} innerRadius={70} outerRadius={100} fill="#82ca9d">
                        {
                          data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                        }
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <Row style={{ position: "relative", top: "-1.5rem" }}>
                  <Col className="text-center">
                    <span className="text-muted">This Week</span>
                    <h5>112</h5>
                  </Col>
                  <Col className="text-center">
                    <span className="text-muted">Last Week</span>
                    <h5>80</h5>
                  </Col>
                </Row>
                <p className="text-center">
                  <Button color="primary" outline>View detailed report</Button>
                </p>
              </CardBody>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col>
            <AdvancedCard 
              title="Today Sales" 
              noPadding
              options={[
                {label: 'Refresh', icon: <FiRefreshCcw /> },
                {label: 'Shipments', icon: <FiPackage /> },
                {label: 'Print', icon: <FiPrinter /> }
              ]}
            >
              <Table className="table-vertical-middle" responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span>#752363</span><br/>
                      <small className="text-muted">23m ago</small>
                    </td>
                    <td>
                      <span>Ben Rhodey</span><br/>
                      <small className="text-muted">ben@gmail.com</small>
                    </td>
                    <td>$125</td>
                    <td><Badge color="success">Completed</Badge></td>
                    <td><Button color="link"><FiEye /> View</Button></td>
                  </tr>
                  <tr>
                    <td>
                      <span>#752362</span><br/>
                      <small className="text-muted">1h ago</small>
                    </td>
                    <td>
                      <span>Phillips</span><br/>
                      <small className="text-muted">phillips@live.com</small>
                    </td>
                    <td>$207</td>
                    <td><Badge color="warning">Pending</Badge></td>
                    <td><Button color="link"><FiArrowUpRight /> Follow Up</Button></td>
                  </tr>
                  <tr>
                    <td>
                      <span>#752361</span><br/>
                      <small className="text-muted">1h ago</small>
                    </td>
                    <td>
                      <span>Andrea Moore</span><br/>
                      <small className="text-muted">moore@gmail.com</small>
                    </td>
                    <td>$87</td>
                    <td><Badge color="danger">Failed</Badge></td>
                    <td><Button color="link"><FiArrowUpRight /> Follow Up</Button></td>
                  </tr>
                  <tr>
                    <td>
                      <span>#752360</span><br/>
                      <small className="text-muted">1h ago</small>
                    </td>
                    <td>
                      <span>Mary Jane</span><br/>
                      <small className="text-muted">jane@gmail.com</small>
                    </td>
                    <td>$314</td>
                    <td><Badge color="success">Completed</Badge></td>
                    <td><Button color="link"><FiEye /> View</Button></td>
                  </tr>
                  <tr>
                    <td>
                      <span>#752359</span><br/>
                      <small className="text-muted">2h ago</small>
                    </td>
                    <td>
                      <span>Michael Downey</span><br/>
                      <small className="text-muted">downey@gmail.com</small>
                    </td>
                    <td>$222</td>
                    <td><Badge color="success">Completed</Badge></td>
                    <td><Button color="link"><FiEye /> View</Button></td>
                  </tr>
                </tbody>
              </Table>
            </AdvancedCard>
          </Col>
          <Col>
            <AdvancedCard
              title="Campaigns"
              noPadding
            >
              <Table className="table-borderless" responsive={true}>
                <tbody>
                  {dataCampaign.map((item, idx) => {
                    let color, icon;

                    switch(item.channel) {
                      case 'Facebook':
                        color = '#3b5998';
                        icon = <IconFacebok size="20" />;
                        break;
                      case 'Twitter':
                        color = '#1da1f2';
                        icon = <IconTwitter size="20" />;
                        break;
                      case 'Instagram':
                        color = '#c13584';
                        icon = <IconInstagram size="20" />;
                        break;
                      case 'YouTube':
                        color = '#ff0000';
                        icon = <IconYoutube size="20" />;
                        break;
                      case 'Email':
                        color = '#585a60';
                        icon = <IconEmail size="20" />;
                        break;
                      default:
                    }

                    return (
                      <tr key={idx}>
                        <td className="d-flex align-items-center">
                          <div><IconCircle bgColor={color} className="mr-2">{icon}</IconCircle></div>
                          <span>{item.channel}<br/> <span className="text-muted">{item.result}</span></span>
                        </td>
                        <td>
                          <strong>{item.budget}</strong><br/>
                          <span className="text-muted">Budget</span>
                        </td>
                        <td>
                          <strong>{item.remaining}</strong><br/>
                          <span className="text-muted">Remaining</span>
                        </td>
                        <td>
                          <strong>{item.roi}</strong><br/>
                          <span className="text-muted">ROI</span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </AdvancedCard>
          </Col>
        </Row>
      </Fragment>
    );
  }
};