import React from 'react';
import { 
  Card, CardBody, CardTitle,
  Row, Col,
  Table
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { 
  GoDiffAdded as IconAdd,
  GoDiffRemoved as IconMinus
} from 'react-icons/go';

const columnsUsers = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true
  },
  {
    dataField: 'role',
    text: 'Role',
    sort: true,
    editor: {
      type: Type.SELECT,
      options: [
        {
          value: 'Subscriber',
          label: 'Subscriber',
        },
        {
          value: 'Manager',
          label: 'Manager',
        },
        {
          value: 'Administrator',
          label: 'Administrator',
        },
      ]
    }
  },
  {
    dataField: 'expired',
    text: 'Expired',
    sort: true,
    formatter: (cell) => {
      let dateObj = cell;
      if (typeof cell !== 'object') {
        dateObj = new Date(cell);
      }
      return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getMonth() + 1)).slice(-2)}/${dateObj.getFullYear()}`;
    },
    editor: {
      type: Type.DATE
    }
  },
  {
    dataField: 'notes',
    text: 'Notes',
    sort: true,
    editor: {
      type: Type.TEXTAREA
    }
  }
];

const users = [
  {id: 1, name: 'Bob', role: 'Subscriber', expired: '7/22/2022', notes: 'Some notes'},
  {id: 2, name: 'Jane', role: 'Manager', expired: '7/20/2022', notes: 'Jane notes'},
  {id: 3, name: 'Smith', role: 'Administrator', expired: '3/20/2022', notes: 'Another notes'}
]

const columnsProducts = [
  {
    dataField: 'browser',
    text: 'Browser',
    sort: true
  },
  {
    dataField: 'os',
    text: 'OS',
    sort: true
  },
  {
    dataField: 'visits',
    text: 'Visits',
    sort: true
  },
  {
    dataField: 'newVisits',
    text: 'New Visits',
    sort: true,
    formatter: percentFormatter
  } 
];

const products = [
  {id: 1, browser: 'Chrome', os: 'Macintosh', visits: 360, newVisits: 82.78},
  {id: 2, browser: 'Chrome', os: 'Windows', visits: 582, newVisits: 87.24},
  {id: 3, browser: 'Firefox', os: 'Windows', visits: 210, newVisits: 83.11},
  {id: 4, browser: 'Chrome', os: 'Macintosh', visits: 360, newVisits: 82.78},
  {id: 5, browser: 'Chrome', os: 'Windows', visits: 582, newVisits: 87.24},
  {id: 6, browser: 'Firefox', os: 'Windows', visits: 210, newVisits: 83.11},
  {id: 7, browser: 'Chrome', os: 'Macintosh', visits: 360, newVisits: 82.78},
  {id: 8, browser: 'Chrome', os: 'Windows', visits: 582, newVisits: 87.24},
  {id: 9, browser: 'Firefox', os: 'Windows', visits: 210, newVisits: 83.11},
  {id: 10, browser: 'Chrome', os: 'Macintosh', visits: 360, newVisits: 82.78},
  {id: 11, browser: 'Chrome', os: 'Windows', visits: 582, newVisits: 87.24},
  {id: 12, browser: 'Firefox', os: 'Windows', visits: 210, newVisits: 83.11}
];

function percentFormatter(cell, row) {
  return `${cell}%`;
}

const expandRow = {
  renderer: row => (
    <div>
      <p>{ `This Expand row is belong to rowKey ${row.id}` }</p>
      <p>You can render anything here, also you can add additional data on every row object</p>
      <p>expandRow.renderer callback will pass the origin row object to you</p>
    </div>
  ),
  showExpandColumn: true,
  onlyOneExpanding: true,
  expandHeaderColumnRenderer: ({ isAnyExpands }) => {
    if (isAnyExpands) {
      return <IconMinus className="cursor-pointer" />;
    }
    return <IconAdd className="cursor-pointer" />;
  },
  expandColumnRenderer: ({ expanded }) => {
    if (expanded) {
      return <IconMinus className="cursor-pointer" />;
    }
    return <IconAdd className="cursor-pointer" />;
  }
}

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

export default (props) => (
  <Row>
    <Col lg="7">
      <Card>
        <CardBody>
          <CardTitle>React Bootstrap Table</CardTitle>
          <BootstrapTable 
            bordered={false}
            wrapperClasses="table-responsive"
            pagination={paginationFactory()}
            bootstrap4
            keyField='id' 
            columns={columnsProducts} 
            data={products}
            selectRow={{
              mode: 'checkbox',
              clickToSelect: true,
              selectionHeaderRenderer: ({ indeterminate, ...rest }) => (
                <div className="custom-checkbox custom-control">
                  <input 
                    type="checkbox" 
                    className="custom-control-input"
                    onChange={() => { return; }}
                    ref={ (input) => {
                      if (input) input.indeterminate = indeterminate;
                    } }
                    { ...rest }
                  />
                  <label className="custom-control-label"></label>
                </div>
              ),
              selectionRenderer: ({ mode, rowIndex, ...rest }) => {
                return (
                  <div className="custom-checkbox custom-control">
                    <input 
                      type={mode} 
                      className="custom-control-input"
                      onChange={() => { return; }}
                      rowindex={rowIndex}
                      {...rest}
                    />
                    <label className="custom-control-label"></label>
                  </div>
                )
              }
            }}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Cell Editing</CardTitle>
          <p>Try to click the table cell</p>
          <BootstrapTable 
            bordered={false}
            wrapperClasses="table-responsive"
            bootstrap4
            keyField='id' 
            columns={columnsUsers} 
            data={users}
            cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Expandable Rows</CardTitle>
          <BootstrapTable 
            bordered={false}
            wrapperClasses="table-responsive"
            bootstrap4
            keyField='id' 
            columns={columnsUsers} 
            data={users}
            expandRow={expandRow}
            rowStyle={{ cursor: 'pointer' }}
          />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Table Search &amp; Export</CardTitle>
          <ToolkitProvider
            bordered={false}            
            bootstrap4
            keyField='id' 
            columns={columnsUsers} 
            data={users}
            search
            exportCSV
          >
            {
              props => (
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <ExportCSVButton className="btn btn-secondary" { ...props.csvProps }>Export CSV</ExportCSVButton>  
                    </div>
                    <div>
                      <SearchBar {...props.searchProps} />  
                    </div>
                  </div>
                  <BootstrapTable 
                    {...props.baseProps}
                    wrapperClasses="table-responsive"
                  />
                </div>
              )
            }
          </ToolkitProvider>
        </CardBody>
      </Card>
    </Col>
    <Col lg="5">
      <Card>
        <CardBody>
          <CardTitle>Basic Table</CardTitle>
          <Table responsive>
            <thead>
              <tr>
                <th>Browser</th>
                <th>OS</th>
                <th>Visits</th>
                <th>New Visits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Basic table with darker header</CardTitle>
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th>Browser</th>
                <th>OS</th>
                <th>Visits</th>
                <th>New Visits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Bordered Table</CardTitle>
          <Table bordered responsive>
            <thead>
              <tr>
                <th>Browser</th>
                <th>OS</th>
                <th>Visits</th>
                <th>New Visits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td align="right">210</td>
                <td align="right">83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td align="right">210</td>
                <td align="right">83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td align="right">210</td>
                <td align="right">83.11%</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Striped Table</CardTitle>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Browser</th>
                <th>OS</th>
                <th>Visits</th>
                <th>New Visits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Hovered Table</CardTitle>
          <Table hover responsive>
            <thead>
              <tr>
                <th>Browser</th>
                <th>OS</th>
                <th>Visits</th>
                <th>New Visits</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
              <tr>
                <td>Firefox</td>
                <td>Windows</td>
                <td>210</td>
                <td>83.11%</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  </Row>
      
);