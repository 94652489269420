import React, { Component, Fragment } from 'react';
import { 
  Card, CardBody, CardTitle,
  Row, Col, Button
} from 'reactstrap';
import SweetAlert from 'sweetalert2-react';
import PageHeader from 'shared/components/page-header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

window.toast = toast;

class SweetAlertDemo extends Component{
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      type: 'info',
      title: '',
      text: '',
      confirmDialogShow: false,
      customDialogShow: false
    }
  }

  showAlert = (type) => {
    let title, text;

    if(type === 'success') {
      title = 'Good Job!';
      text = 'You clicked the button';
    } else if(type === 'warning') {
      title = 'Warning!';
      text = 'Your storage is almost full';
    } else if(type === 'error') {
      title = 'Error!';
      text = 'Please check again';
    } else if(type === 'info') {
      title = 'Info!';
      text = 'Please read this information';
    }

    this.setState({
      show: true,
      type: type,
      title: title,
      text: text
    })
  }

  showConfirmDialog = () => {
    this.setState({
      confirmDialogShow: true
    })
  }

  showCustomDialog = () => {
    this.setState({
      customDialogShow: true
    })
  }  

  render() {
    const {
      show,
      type,
      title,
      text,
      confirmDialogShow,
      customDialogShow
    } = this.state;

    return(    
      <Fragment>
        <PageHeader title="Sweet Alert" description="Fancy alert pop-up" />
        <Row>
          <Col md="6">
            <Card>
              <CardBody>
                <CardTitle>Contextual</CardTitle>
                <Row className="align-items-center mb-2">
                  <Col><Button color="success" onClick={() => this.showAlert('success')}>Click Me</Button></Col>
                  <Col><span>A success message</span></Col>
                </Row>
                <Row className="align-items-center mb-2">
                  <Col><Button color="warning" onClick={() => this.showAlert('warning')}>Click Me</Button></Col>
                  <Col><span>A warning message</span></Col>
                </Row>
                <Row className="align-items-center mb-2">
                  <Col><Button color="danger" onClick={() => this.showAlert('error')}>Click Me</Button></Col>
                  <Col><span>An error message</span></Col>
                </Row>
                <Row className="align-items-center mb-2">
                  <Col><Button color="info" onClick={() => this.showAlert('info')}>Click Me</Button></Col>
                  <Col><span>An info message</span></Col>
                </Row>                
                <SweetAlert 
                  show={show}
                  type={type}
                  title={title}
                  text={text}
                  buttonsStyling={false}
                  confirmButtonClass='btn btn-success'
                  onConfirm={() => this.setState({ show: false })}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md="6">
          <Card>
            <CardBody>
              <CardTitle>Advanced</CardTitle>
              <Row className="align-items-center mb-2">
                <Col><Button color="danger" onClick={this.showConfirmDialog}>Delete File</Button></Col>
                <Col><span>Confirmation prior action</span></Col>
              </Row>
              <SweetAlert 
                show={confirmDialogShow}
                type="warning"
                title="Are you sure?"
                text="You won't be able to revert"
                showCancelButton={true}
                confirmButtonText="Yes, delete it!"
                cancelButtonText="No, cancel!"
                confirmButtonClass="btn btn-danger"
                cancelButtonClass="btn btn-success mr-2"
                reverseButtons={true}
                buttonsStyling={false}
                onConfirm={() => {
                  toast.success("Your file has been deleted.");
                  this.setState({confirmDialogShow: false});
                }}
                onCancel={() => {
                  toast.info("No file is deleted.")
                  this.setState({confirmDialogShow: false});
                }}                
              />              
              <ToastContainer position="bottom-right" autoClose={2000} />
              <Row className="align-items-center mb-2">
                <Col><Button color="secondary" onClick={this.showCustomDialog}>Click Me</Button></Col>
                <Col><span>HTML Custom Content</span></Col>
              </Row>
                <SweetAlert 
                  show={customDialogShow}
                  title="Custom Image"
                  imageUrl="http://i.imgur.com/zhhZWFS.png"
                  buttonsStyling={false}
                  confirmButtonClass="btn btn-success"
                  onConfirm={() => this.setState({customDialogShow: false})}
                />              
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

export default SweetAlertDemo;